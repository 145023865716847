import * as React from 'react'
import Svg from 'svg'
import checkIcon from 'uiComponents/input/checkIcon.svg'
import bigTickIcon from 'uiComponents/input/bigTick.svg'
import classNames from 'classnames'
import './checkbox.scss'

export interface CheckboxProps {
    indeterminateState?: boolean
    markFocus?: boolean
    bordered?: boolean
    innerRef?: any
    disabled?: boolean
}

export function Checkbox({ innerRef, ...props }: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) {
    return (
        <div className="checkbox-container">
            <input
                {...props}
                type="checkbox"
                ref={innerRef}
                className={classNames(
                    'checkbox-input',
                    {
                        'mark-focus': props.markFocus,
                        bordered: props.bordered,
                        disabled: props.disabled,
                    },
                    props.className,
                )}
                onChange={props.onChange ? props.onChange : () => {}}
            />
            {props.indeterminateState && <span className="dash-mark">&ndash;</span>}
            {!props.indeterminateState && <Svg className="check-mark" src={checkIcon} />}
        </div>
    )
}

export function BigCheckbox(props: React.InputHTMLAttributes<HTMLInputElement>) {
    return (
        <div className="checkbox-container">
            <input type="checkbox" className="checkbox-input big" {...props} />
            <Svg className="check-mark big" src={bigTickIcon} />
        </div>
    )
}
