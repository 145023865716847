import React, { FC, useMemo } from 'react'
import { Form, Formik } from 'formik'
import * as config from 'config'
import ActionButton from 'uiComponents/buttons'
import RadioFieldFormik from 'uiComponents/input/radio/radioFieldFormik'
import { replaceMessage } from 'uiComponents/messages/actions'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { delay } from 'utils'
import { useDownloadLink } from 'orders/orders/utils'
import { CancelButton } from 'uiComponents/buttons/cancelButton'
import { useScheduleAsyncExportMutation } from 'orders/reduxQueries'
import './cartExportModal.scss'
import { useAppSelector } from 'store/hooks'
import { useNavigation } from 'hocs'
import { dateRangeFromQuery } from 'dateRanges'
import { differenceInDays, format } from 'date-fns'
import { getFiltersFromUrl } from 'uiComponents/filter/filterHelpers'
import { camelToSnakeCase, convertToSnakeCase } from 'utils/camelToSnake'
import { SortProps } from 'uiComponents/table/reactTable'
import { useMessages } from 'messagesContext'

const backofficeEndpoint = config.getRequired('backoffice-endpoint')

interface FormValues {
    format: 'csv' | 'xlsx'
    dateSelection: 'all' | 'current'
}

const InitialExportValues: FormValues = {
    format: 'csv',
    dateSelection: 'all',
}

export type ExportReservationsPayload = {
    export_format: string
    status: string
    filter_by_date: string
    ordering: string | undefined
    date_from: string
    date_to: string
    include: string
}

type CartExportModalProps = {
    onClose: () => void
    sort: SortProps | null
}

const CartExportModal: FC<CartExportModalProps> = ({ onClose, sort }) => {
    const navigation = useNavigation()
    const accountSlug = useAppSelector((state) => state.preferences.activeAccount ?? '')
    const downloadLink = useDownloadLink()
    const [scheduleAsyncExport] = useScheduleAsyncExportMutation()
    const query = navigation.query()
    const { replaceMessages, removeAllMessages } = useMessages()

    const statusFilter = useMemo(() => getFiltersFromUrl('reservation_status'), [navigation])

    const dateFilterParams = useMemo(() => {
        const dateRange = dateRangeFromQuery(query, 'last30days')
        if (query.search || query.q) {
            return {
                dateFrom: '',
                dateTo: '',
                filterByDate: 'created_at',
            }
        }

        return {
            dateFrom: format(dateRange.from, 'yyyy-MM-dd'),
            dateTo: format(dateRange.to, 'yyyy-MM-dd'),
            filterByDate: query.dateRangeType === 'visit' ? 'event_date' : 'created_at',
        }
    }, [query])

    const getDownloadLinkProps = (format: string): ExportReservationsPayload => {
        const orderKey = camelToSnakeCase(sort?.prop || 'created_at')
        const ordering = !orderKey ? undefined : sort?.direction === 'asc' ? orderKey : `-${orderKey}`
        
        return {
            export_format: format,
            status: statusFilter,
            include: 'customer,urls,items.product_name,comment',
            ordering,
            ...convertToSnakeCase(dateFilterParams),
        }
    }

    const getDownloadLink = (dateRangeMoreThanOneDay: boolean) => {
        return `${backofficeEndpoint}dashboard_api/accounts/${accountSlug}/export/carts/${
            dateRangeMoreThanOneDay ? 'schedule/' : ''
        }`
    }

    const onSubmit = async (values: FormValues) => {
        try {
            const dateRangeMoreThanOneDay =
                differenceInDays(new Date(dateFilterParams.dateTo), new Date(dateFilterParams.dateFrom)) >= 1

            const endpoint = getDownloadLink(dateRangeMoreThanOneDay)
            if (dateRangeMoreThanOneDay) {
                const payload = getDownloadLinkProps(values.format)
                await scheduleAsyncExport({ endpoint, payload })
                replaceMessages(
                    'schedule_success',
                    'success',
                    'Request was placed successfully. An email with be sent to you shortly.',
                )
                onClose()
                await delay(6000)
                removeAllMessages()
            } else {
                const payload = getDownloadLinkProps(values.format)

                await downloadLink(endpoint, payload)
                replaceMessage(
                    'schedule_success',
                    'success',
                    'Report generation may take up to 30 seconds and will download automatically.',
                )
                onClose()
                await delay(6000)
                removeAllMessages()
                return
            }
        } catch {
            replaceMessages('server_error', 'error', 'Oops! Something went wrong. Please try again.')
        }
    }

    return (
        <ModalDialog onDismiss={onClose} interactive fromTop="20%" className="cart-export-modal">
            <ModalDialogTitle>Export reservations</ModalDialogTitle>
            <Formik initialValues={InitialExportValues} onSubmit={onSubmit} enableReinitialize>
                {({ values }) => (
                    <Form>
                        <div className="questions-container">
                            <div className="format">
                                <h6>Format</h6>
                                <div className="format-row">
                                    <RadioFieldFormik
                                        variant="inline-reverse"
                                        name="format"
                                        value="csv"
                                        label=".CSV"
                                        checkMarkSize="medium"
                                        className={values.format === 'csv' ? 'selected' : ''}
                                        id="export-csv"
                                    />
                                    <RadioFieldFormik
                                        variant="inline-reverse"
                                        name="format"
                                        value="xlsx"
                                        label=".XLSX"
                                        checkMarkSize="medium"
                                        className={values.format === 'xlsx' ? 'selected' : ''}
                                        id="export-xlsx"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="buttons-container">
                            <CancelButton size="large" secondary onClick={onClose} data-testid="export-cancel-button">
                                Cancel
                            </CancelButton>
                            <ActionButton size="large" kind="default" type="submit" data-testid="export-exports-button">
                                Export
                            </ActionButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalDialog>
    )
}

export default CartExportModal
