import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { OrderRefundDetails } from '../types'
import CancelRefundDialog from '../components/dialog'
import { CurrencyProps, withCurrency } from 'uiComponents/money/moneyHoc'
import { useBulkRefundOrderMutation, useLazyGetOrderDetailsForRefundQuery } from 'orders/reduxQueries'
import {
    calculateTotalRefundFee,
    filterNonRefundableOrders,
    getSelectedBookingFees,
    getTotalRefundedAmount,
} from './utils'
import { IOrder } from 'orders/schema'
import { mapHeaderProps, mapFooterProps, prepareTableData } from './mappings'
import { ReplaceMessagesFunc } from 'messagesContext'
import { useHasFeature } from 'utils/useHasFeature'

interface BulkCancelRefundProps extends CurrencyProps {
    accountSlug: string
    orders: IOrder[]
    isCancellation: boolean
    onClose: () => void
    formatCurrencyString: (amount: number | string, accountSlug: string) => string
    replaceMessages: ReplaceMessagesFunc
}

const BulkCancelRefund = ({
    accountSlug,
    isCancellation,
    orders,
    replaceMessages,
    onClose,
    formatCurrencyString,
}: BulkCancelRefundProps) => {
    const [getOrderDetailsForRefund, { isLoading }] = useLazyGetOrderDetailsForRefundQuery()
    const [ordersForRefund, setOrdersForRefund] = useState<OrderRefundDetails[]>([])
    const [selectedOrders, setSelectedOrders] = useState<string[]>(orders.map((order) => order.id))
    const [confirmMode, setConfirmMode] = useState<boolean>(false)
    const [selectRefundFee, setSelectRefundFee] = useState<boolean>(true)
    const [cancelRefundReason, setCancelRefundReason] = useState<string>('')
    const [refundOrder, { isLoading: refunding }] = useBulkRefundOrderMutation()
    const bookingFeesEnabled = useHasFeature({ featureName: 'BookingFees' })
    const [selectedBookingFees, setSelectedBookingFees] = useState<string[]>([])

    useEffect(() => {
        let isCancelled = false

        const loadData = async () => {
            try {
                const responses = await Promise.all(
                    orders.map((order) => getOrderDetailsForRefund({ orderId: order.id }).unwrap()),
                )

                if (responses.some((response) => !response) && !isCancelled) {
                    replaceMessages('server_error', 'error', '')
                }

                if (!isCancelled) {
                    if (responses.some((response) => response === undefined)) {
                        replaceMessages('server_error', 'error', '')
                    } else {
                        setOrdersForRefund(filterNonRefundableOrders(responses as OrderRefundDetails[]))
                        setSelectedBookingFees(getSelectedBookingFees(responses as OrderRefundDetails[]))
                    }
                }
            } catch (error) {
                if (!isCancelled) {
                    replaceMessages('server_error', 'error', '')
                }
            }
        }

        loadData()

        return () => {
            isCancelled = true
        }
    }, [])

    const refund = useCallback(async () => {
        try {
            const response = await refundOrder({
                accountSlug,
                orderUuids: selectedOrders,
                bookingFeeUuids: selectedBookingFees,
                refundReason: cancelRefundReason,
                forgoPartnerRefundFee: isCancellation,
                isCancellation,
            }).unwrap()

            if (response) {
                onClose()
                const message = isCancellation
                    ? 'Bulk orders cancellation request was successful'
                    : 'Bulk orders refund request was successfully placed and is being processed'
                replaceMessages('refunded_successfully', 'success', message)
            } else {
                onClose()
            }
        } catch (error) {
            replaceMessages('refund_error', 'error', 'An error occurred during the refund process.')
            onClose()
        }
    }, [
        accountSlug,
        selectedOrders,
        cancelRefundReason,
        refundOrder,
        onClose,
        replaceMessages,
        isCancellation,
        selectedBookingFees,
    ])

    const handleTextAreaChange = useCallback((event) => {
        setCancelRefundReason(event.target.value)
    }, [])

    const handleItemSelect = useCallback(
        (_parentId: string | null, id: string) => {
            const orderBookingFees =
                ordersForRefund
                    .find((order) => order.id === id)
                    ?.refundOrderItems.flatMap((ticket) =>
                        ticket.refundTickets.flatMap((ticket) => ticket.bookingFees.map((fee) => fee.uuid)),
                    ) ?? []
            if (selectedOrders.includes(id)) {
                setSelectedOrders((prev) => prev.filter((prevId) => prevId !== id))
                setSelectedBookingFees((prev) => prev.filter((fee) => !orderBookingFees.includes(fee)))
            } else {
                setSelectedOrders((prev) => [...prev, id])
                setSelectedBookingFees((prev) => [...prev, ...orderBookingFees])
            }
        },
        [setSelectedOrders, selectedOrders, setSelectedBookingFees, ordersForRefund],
    )

    const handleBookingFeeSelect = useCallback(
        (_parentId: string | null, id: string) => {
            if (selectedBookingFees.includes(id)) {
                setSelectedBookingFees((prev) => prev.filter((x) => x !== id))
            } else {
                setSelectedBookingFees((prev) => [...prev, id])
            }
        },
        [selectedBookingFees],
    )

    const onCancel = useCallback(() => {
        if (confirmMode) {
            setConfirmMode(false)
        } else {
            onClose()
        }
    }, [confirmMode, onClose])

    const onNext = useCallback(() => {
        if (confirmMode) {
            refund()
        } else {
            setConfirmMode(true)
        }
    }, [confirmMode, refund])

    const partnerRefundFeeAmount = useMemo(() => {
        if (selectedOrders.length === 0 || isCancellation) {
            return 0
        }
        return calculateTotalRefundFee(
            ordersForRefund.filter((order) => selectedOrders.includes(order.id)),
            selectedBookingFees,
        )
    }, [ordersForRefund, selectedOrders, isCancellation, selectedBookingFees])

    const totalAmountToRefund = useMemo(() => {
        return getTotalRefundedAmount(
            ordersForRefund.filter((order) => selectedOrders.includes(order.id)),
            !isCancellation && selectRefundFee,
            selectedBookingFees,
        )
    }, [ordersForRefund, selectedOrders, selectRefundFee, isCancellation, selectedBookingFees])

    const tableData = useMemo(() => {
        const ordersForRefundNotEmpty = ordersForRefund.length > 0

        return prepareTableData({
            orders: ordersForRefund,
            selectedOrders: selectedOrders,
            totalAmount: totalAmountToRefund,
            partnerRefundFeeAmount: partnerRefundFeeAmount,
            partnerRefundFeeOff: isCancellation,
            partnerRefundFeeSelected: selectRefundFee,
            formatCurrencyString: formatCurrencyString,
            partnerRefundFee: ordersForRefundNotEmpty ? ordersForRefund[0]?.partnerRefundFee : undefined,
            partnerRefundFeeType: ordersForRefundNotEmpty ? ordersForRefund[0]?.partnerRefundFeeType : undefined,
            bookingFeesEnabled,
            selectedBookingFees,
            confirmMode,
        })
    }, [
        ordersForRefund,
        selectedOrders,
        totalAmountToRefund,
        partnerRefundFeeAmount,
        isCancellation,
        selectRefundFee,
        formatCurrencyString,
        bookingFeesEnabled,
        selectedBookingFees,
        confirmMode,
    ])

    const footerProps = useMemo(
        () =>
            mapFooterProps({
                accountSlug: accountSlug,
                confirmMode: confirmMode,
                isCancellation,
                selectedOrders: selectedOrders,
                refunding: refunding,
                onCancel: onCancel,
                onNext: onNext,
                totalAmount: totalAmountToRefund,
                refundDisallowed: totalAmountToRefund <= 0,
                formatCurrencyString: formatCurrencyString,
            }),
        [
            accountSlug,
            confirmMode,
            isCancellation,
            refunding,
            onCancel,
            onNext,
            totalAmountToRefund,
            selectedOrders,
            partnerRefundFeeAmount,
            formatCurrencyString,
        ],
    )

    const notesProps = useMemo(
        () => ({ cancelRefundReason, handleTextAreaChange, isCancellation }),
        [cancelRefundReason, handleTextAreaChange, isCancellation],
    )

    const headerProps = useMemo(() => mapHeaderProps(isCancellation, onClose), [isCancellation, onClose])

    return (
        <CancelRefundDialog
            accountSlug={accountSlug}
            headerProps={headerProps}
            footerProps={footerProps}
            tableData={tableData}
            confirmMode={confirmMode}
            loading={isLoading}
            refunding={refunding}
            handleItemSelect={handleItemSelect}
            handleBookingFeeSelect={handleBookingFeeSelect}
            handleRefundFeeSelect={() => setSelectRefundFee((prev) => !prev)}
            notesProps={notesProps}
        />
    )
}

export default withCurrency(BulkCancelRefund)
